import React from "react";
import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
// import 'react-phone-number-input/style.css'
// import "react-hot-toast/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "filepond/dist/filepond.min.css";
// contexts
import SettingsProvider from "./contexts/SettingsContext";
import App from "./App";
import LoadDataComponent from "pages/dashboard/LoadDataComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <SettingsProvider>
          <BrowserRouter>
            <LoadDataComponent>
              <App />
            </LoadDataComponent>
            <Toaster />
          </BrowserRouter>
        </SettingsProvider>
      </ReduxProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
