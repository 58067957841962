import React, { useEffect, useState } from "react";

import "./nightDay.css";
import NightDay2 from "./nightDay2";

export const NightDayBtn = ({ onChange, defaultChecked }) => {
  const [isDarkMode, setIsDarkMode] = useState(defaultChecked);

  // useEffect(() => {
  //   const handleSwitchChange = () => {
  //     onChange();
  //     document.body.classList.toggle("dark", isDarkMode);
  //   };

  //   const switchInput = document.querySelector(".switch__input");
  //   switchInput.addEventListener("change", handleSwitchChange);

  //   return () => {
  //     switchInput.removeEventListener("change", handleSwitchChange);
  //   };
  // }, [isDarkMode]);

  const handleSwitchChange = () => {
    onChange();

    setIsDarkMode((prev) => !prev);
  };
  return (
    <div>
      <label className='switch'>
        <input
          className={`switch__input dark`}
          type='checkbox'
          role='switch'
          checked={isDarkMode}
          onChange={handleSwitchChange}
          name='daynightmode'
        />
        <svg
          className={`switch__icon ${isDarkMode ? "switch__icon--dark" : "switch__icon--light"}`}
          viewBox='0 0 12 12'
          width='18px'
          height='18px'
          aria-hidden='true'
        >
          {isDarkMode ? (
            <g fill='none' stroke='#fff' strokeWidth='1' strokeLinejoin='round' transform='rotate(-45,6,6)'>
              <path d='m9,10c-2.209,0-4-1.791-4-4s1.791-4,4-4c.304,0,.598.041.883.105-.995-.992-2.367-1.605-3.883-1.605C2.962.5.5,2.962.5,6s2.462,5.5,5.5,5.5c1.516,0,2.888-.613,3.883-1.605-.285.064-.578.105-.883.105Z' />
            </g>
          ) : (
            <g fill='none' stroke='#fff' strokeWidth='1' strokeLinecap='round'>
              <circle cx='6' cy='6' r='2' />
              <g strokeDasharray='1.5 1.5'>
                <polyline points='6 10,6 11.5' transform='rotate(0,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(45,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(90,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(135,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(180,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(225,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(270,6,6)' />
                <polyline points='6 10,6 11.5' transform='rotate(315,6,6)' />
              </g>
            </g>
          )}
        </svg>
      </label>
    </div>
  );
};
export default NightDayBtn;
