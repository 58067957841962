import { faker } from "@faker-js/faker";
import { ChatCircleDots, Gear, GearSix, Phone, SignOut, User, Users } from "phosphor-react";

const Profile_Menu = [
  {
    title: "Profile",
    icon: <User />,
  },
  // {
  //   title: "Settings",
  //   icon: <Gear />,
  // },
  {
    title: "Sign Out",
    icon: <SignOut />,
  },
];

const Nav_Buttons = [
  {
    index: 0,
    icon: <ChatCircleDots size={20} />,
    pathname: "./app",
  },
  // {
  //   index: 1,
  //   icon: <Users />,
  //   pathname: "./Users",
  // },
  // {
  //   index: 2,
  //   icon: <Phone />,
  //   pathname: "./Phone",
  // },
];

const Nav_Setting = [
  // {
  //   index: 3,
  //   icon: <GearSix />,
  // },
];

const CallList = [
  {
    id: 0,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
  {
    id: 1,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: false,
    missed: true,
  },
  {
    id: 2,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: true,
    missed: true,
  },
  {
    id: 3,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: false,
    missed: false,
  },
  {
    id: 4,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
  {
    id: 5,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: false,
    missed: false,
  },
  {
    id: 6,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
  {
    id: 7,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: false,
    missed: false,
  },
  {
    id: 8,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
  {
    id: 9,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: false,
    missed: false,
  },
  {
    id: 10,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
  {
    id: 11,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: false,
    incoming: false,
    missed: false,
  },
  {
    id: 12,
    img: faker.image.avatar(),
    name: faker.name.firstName(),
    online: true,
    incoming: true,
    missed: false,
  },
];
// {
//   id: chatId,
//   img: faker.image.avatar(),
//   name: "ali",
//   msg: faker.music.songName(),
//   online: true,
// },
let ChatList = [
  {
    _id: "abcd1",
    img: faker.image.avatar(),
    name: "akbar",
    msg: faker.music.songName(),
    time: "04:00",
    unread: 0,
    pinned: false,
    online: true,
  },
  {
    id: "abcd2",
    img: faker.image.avatar(),
    name: "ali",
    msg: faker.music.songName(),
    time: "04:00",
    unread: 0,
    pinned: false,
    online: true,
  },
  // {
  //   id: "abcd3",
  //   img: faker.image.avatar(),
  //   name: "zeeshan",
  //   msg: faker.music.songName(),
  //   time: "04:00",
  //   unread: 0,
  //   pinned: false,
  //   online: true,
  // },
];

const Chat_History = [
  {
    chatId: "abcd1",
    chatHistory: [
      {
        type: "msg",
        message: "I am akbar who are you?",
        incoming: true,
        outgoing: false,
      },
      // {
      //   type: "divider",
      //   text: "Today",
      // },
      {
        type: "msg",
        subtype: "img",
        message: "Here You Go",
        img: faker.image.abstract(),
        incoming: true,
        outgoing: false,
      },
    ],
  },
  // {
  //   chatId: "65a2d818f853102062144ffe",
  //   chatHistory: [
  //     {
  //       type: "msg",
  //       message: "im Hq your friend",
  //       incoming: true,
  //       outgoing: false,
  //     },
  //   ],
  // },
];

const Message_options = [
  {
    title: "Save",
  },
  // {
  //   title: "React to message",
  // },
  // {
  //   title: "Forward message",
  // },
  // {
  //   title: "Star message",
  // },
  // {
  //   title: "Report",
  // },
  // {
  //   title: "Delete Message",
  // },
];

const Shared_docs = [
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "doc",
    message: "Yes sure, here you go.",
    incoming: true,
    outgoing: false,
  },
];

const Shared_links = [
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
  {
    type: "msg",
    subtype: "link",
    preview: faker.image.cats(),
    message: "Yep, I can also do that",
    incoming: true,
    outgoing: false,
  },
];

export { Profile_Menu, Nav_Setting, Nav_Buttons, ChatList, Chat_History, Message_options, Shared_links, Shared_docs, CallList };

export const chatArray = [
  [
    {
      chatId: "65a2d818f853102062144ffe",
      chatHistory: [
        {
          chatId: "65a2d818f853102062144ffe",
          type: "msg",
          incoming: false,
          outgoing: true,
          message: "hi",
          messageTime: "Sun, Jan 17, 2024, 1:53:52 AM GMT+5",
        },
        {
          chatId: "65a2d818f853102062144ffe",
          type: "msg",
          incoming: false,
          outgoing: true,
          message: "sd",
          messageTime: "Sun, Jan 17, 2024, 1:54:47 AM GMT+5",
        },
      ],
    },
    {
      chatId: "659a8929bb3daf0f5bf2cab2",
      chatHistory: [
        {
          chatId: "659a8929bb3daf0f5bf2cab2",
          type: "msg",
          incoming: false,
          outgoing: true,
          message: "xda",
          messageTime: "Sun, Jan 19, 2024, 1:55:22 AM GMT+5",
        },
        {
          chatId: "659a8929bb3daf0f5bf2cab2",
          type: "msg",
          incoming: false,
          outgoing: true,
          message: "casd",
          messageTime: "Sun, Jan 19, 2024, 1:56:17 AM GMT+5",
        },
      ],
    },
    {
      chatId: "65998b2035026d7e6006d93d",
      chatHistory: [],
    },
    {
      chatId: "65998b2035026d7e6006d93d",
      chatHistory: [],
    },
  ],
  // Add more chat objects as needed
];
export const histArray = [
  [
    {
      _id: "65a2d818f853102062144ffe",
      userName: "HamQ",
      phoneNumber: "+923024705592",
      isValidated: true,
      createdAt: "Sun, Jan 21, 2024, 1:53:52 AM GMT+5",
      updatedAt: "2024-01-20T20:53:52.062Z",
      __v: 0,
      img: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/907.jpg",
      time: "04:00",
      unread: 0,
      pinned: false,
      online: true,
      name: "HamQ",
      msg: "Iris",
    },
    {
      _id: "659a8929bb3daf0f5bf2cab2",
      userName: "fraz ",
      phoneNumber: "+9233510729673",
      isValidated: true,
      createdAt: "Sun, Jan 21, 2024, 1:55:22 AM GMT+5",
      updatedAt: "2024-01-20T20:55:22.707Z",
      __v: 0,
      img: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/551.jpg",
      time: "04:00",
      unread: 0,
      pinned: false,
      online: true,
      name: "fraz ",
      msg: "Drops of Jupiter (Tell Me)",
    },
  ],
];
