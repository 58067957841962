import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import appReducer from "./slices/app";
import audioCallReducer from "./slices/audioCall";
import videoCallReducer from "./slices/videoCall";
import authReducer from "./slices/auth";
import conversationReducer from "./slices/conversation";
import newChat from "./slices/newChat";
import allContactsSlice from "./slices/AllContacts";

import userProfleReducer from "./slices/userProfile";
import { persistReducer } from "redux-persist";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  // whiteList: ["newChatSystem"],
};

const newChatPersistConfig = {
  key: "newChatSystem",
  storage,
  keyPrefix: "redux-",
  whitelist: ["ChatList"],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  conversation: conversationReducer,
  audioCall: audioCallReducer,
  videoCall: videoCallReducer,
  newChatSystem: persistReducer(newChatPersistConfig, newChat),
  allContacts: allContactsSlice,
  userProfile: userProfleReducer,
});

export { rootPersistConfig, rootReducer };
