import { createSlice } from "@reduxjs/toolkit";

import axios from "../../utils/axios";
import { showSnackbar } from "./app";
import { END_POINTS } from "../../utils/constants";
import { jwtDecode } from "jwt-decode";
import { makeRequest } from "utils/axiosConfig";
import { pushNotification } from "utils/reactToastnotification";
// ----------------------------------------------------------------------
let tokenn =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI2NWFkNGFiODY5MzAwOTU0ZjY4NDQ0YzciLCJwaG9uZU51bWJlciI6Iis5MjMzNTEwNzI5NjciLCJ1c2VyTmFtZSI6Ik11c3RhZmExIiwiaWF0IjoxNzA2NDY1ODU0LCJleHAiOjE3MDcwNzA2NTR9.o-3_1g1eKtV3zfNdBsSPBpsPmEcKxCNAvQvw8m6wS64";

const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")),
  token: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "",
  loginResponse: {
    loginData: {
      _responseCode: "",
      _responseData: "",
    },

    loginOtpdata: {
      loginOtpLoader: false,
      loginOtpResponse: {
        _responseData: "",
        _responseCode: "",
      },
      token: "",
      resendOtpLoader: false,
      resendOtpResponse: {
        _responseData: "",
        _responseCode: "",
      },
    },
  },
  isLoading: false,
  user: null,
  user_id: null,
  email: "",
  error: false,
  registerUserdata: {
    registerLoader: false,
    registerData: {},
    error: false,
    verifyOtpData: {},
    verifyOtpLoader: false,
    otpRegLoader: false,
  },
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      state.error = action.payload.error;
      state.isLoading = action.payload.isLoading;
      state.loginResponse.loginData = action.payload.loginResponse;
    },
    registerLoader(state, action) {
      state.registerUserdata.registerLoader = action.payload.registerLoader;
      state.registerUserdata.registerData = action.payload.registerData;
      state.registerUserdata.error = action.payload.error;
      state.registerUserdata.verifyOtpData = action.payload.verifyOtpData;
    },
    verifyotp(state, action) {
      state.registerUserdata.otpRegLoader = action.payload.otpRegLoader;
      state.registerUserdata.otpRegLoader = action.payload.otpRegLoader;
    },
    loginOtpVerify(state, action) {
      state.loginResponse.loginOtpdata.loginOtpLoader = action.payload.loginOtpLoader;
      state.loginResponse.loginOtpdata.loginOtpResponse = action.payload.loginOtpResponse;
      if (action.payload.loginOtpResponse?._responseCode === 200) {
        localStorage.setItem("userToken", action.payload.loginOtpResponse?._responseData);
        localStorage.setItem("isLoggedIn", true);
        const { userID, phoneNumber } = jwtDecode(localStorage.getItem("userToken"));
        localStorage.setItem("userID", userID);
        localStorage.setItem("phoneNumber", phoneNumber);
        state.isLoggedIn = true;
      }
      // localStorage.setItem("userToken", action.payload.loginOtpResponse?._responseData);
      state.token = action.payload.loginOtpResponse?._responseData;
    },
    signOut(state, action) {
      state.loginResponse.loginOtpdata.loginOtpResponse_responseCode = "";
      state.loginResponse.loginOtpdata.loginOtpResponse_responseData = "";
      state.isLoggedIn = false;
      state.token = "";
      state.user_id = null;
    },
    updateRegisterEmail(state, action) {
      state.email = action.payload.email;
    },
    loginResendOtp(state, action) {
      state.loginResponse.loginOtpdata.resendOtpLoader = action.payload.resendOtpLoader;
      state.loginResponse.loginOtpdata.resendOtpResponse = action.payload.resendOtpResponse;
    },
    resetState(state) {
      // response
      state.loginResponse.loginData._responseCode = "";
      state.loginResponse.loginData._responseData = "";
      // login otp object
      state.loginResponse.loginOtpdata.loginOtpResponse._responseData = "";
      state.loginResponse.loginOtpdata.loginOtpResponse._responseCode = "";
      // resend otp object
      state.loginResponse.loginOtpdata.resendOtpResponse._responseData = "";
      state.loginResponse.loginOtpdata.resendOtpResponse._responseCode = "";

      state.error = "";
    },
  },
});

export const { resetState, signOut } = slice.actions;
// Reducer
export default slice.reducer;

export function NewPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/auth/reset-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
          })
        );
        dispatch(showSnackbar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function ForgotPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    await axios
      .post(
        "/auth/forgot-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response);

        dispatch(showSnackbar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(showSnackbar({ severity: "error", message: error.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function LoginUser(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false, loginResponse: {} }));
      const { data } = await axios.post(
        END_POINTS.USER_LOGIN,
        { ...formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (data._responseCode === 200) {
        pushNotification(`Your OTP ${data._responseData}`, "success", 7000);
      }
      dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false, loginResponse: data }));
    } catch (error) {
      dispatch(slice.actions.updateIsLoading({ isLoading: false, error: true, loginResponse: error }));
    }
  };
}

export function RegisterUser(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.registerLoader({ registerLoader: true, error: false }));
      const { data } = await axios.post(
        END_POINTS.USER_REGISTER,
        { ...formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // const data = await dummyApiCall();
      console.log("inregis user", data);
      if (data._responseCode === 201) {
        pushNotification(`Your OTP ${data._responseData}`, "success", 7000);
      }
      dispatch(slice.actions.registerLoader({ registerLoader: false, error: false, registerData: data }));
    } catch (error) {
      dispatch(slice.actions.registerLoader({ registerLoader: false, error: true, registerData: error }));
    }
  };
}

export function LogoutUser() {
  return async (dispatch, getState) => {
    window.localStorage.removeItem("userToken");
    dispatch(slice.actions.resetState());
    window.localStorage.removeItem("chatId");
    window.localStorage.removeItem("userID");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("phoneNumber");
    localStorage.setItem("isLoggedIn", false);
    dispatch(slice.actions.signOut());
  };
}

export function VerifyRegisterOtp(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.registerLoader({ registerLoader: true, error: false }));
      dispatch(slice.actions.verifyotp({ otpRegLoader: true }));
      const { data } = await axios.post(
        END_POINTS.USER_VERIFY_REGISTER_OTP,
        { ...formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(slice.actions.verifyotp({ otpRegLoader: false }));
      dispatch(slice.actions.registerLoader({ registerLoader: false, error: false, verifyOtpData: data }));
    } catch (error) {
      dispatch(slice.actions.registerLoader({ registerLoader: false, error: true, verifyOtpData: error }));
      dispatch(slice.actions.verifyotp({ otpRegLoader: false }));
    }
  };
}

export function VerifyLoginOtp(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.loginOtpVerify({ loginOtpLoader: true, loggedIn: true, loginOtpResponse: {} }));
      const { data } = await axios.post(
        END_POINTS.USER_VERIFY_LOGIN_OTP,
        { ...formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // const data = await dummyApiCall();
      dispatch(slice.actions.loginOtpVerify({ loginOtpLoader: false, loginOtpResponse: data, loggedIn: true }));
    } catch (error) {
      dispatch(slice.actions.loginOtpVerify({ loginOtpLoader: false, loginOtpResponse: error, loggedIn: false }));
    }
  };
}

export function ResendOTpLogin(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.loginResendOtp({ resendOtpLoader: true, resendOtpResponse: {} }));
      const { data } = await axios.post(
        END_POINTS.RESEND_OTP,
        { ...formValues },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // const data = await dummyApiCall();
      console.log("data resend otp", data);
      if (data._responseCode === 200) {
        pushNotification(`Your OTP ${data._responseData}`, "success", 7000);
      }
      dispatch(slice.actions.loginResendOtp({ resendOtpLoader: false, resendOtpResponse: data }));
    } catch (error) {
      dispatch(slice.actions.loginResendOtp({ resendOtpLoader: false, resendOtpResponse: error }));
    }
  };
}

const dummyApiCall = async () => {
  try {
    const response = await fetch("https://jsonplaceholder.typicode.com/todos/1");
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("error dummy func", error);
  }
};

export function resetSpecificState(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.resetState());
    } catch (error) {
      console.log(error, "Resetstate");
    }
  };
}
