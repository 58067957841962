import React from "react";
import { useTheme } from "@mui/material/styles";

import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import AntSwitch from "../../components/AntSwitch";

import Logo from "../../assets/Images/fav2.png";
import LogoWhite from "../../assets/Images/whiteLogo.png";

import useSettings from "../../hooks/useSettings";
import { Nav_Buttons, Nav_Setting } from "../../data";

import ProfileMenu from "./ProfileMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileTab, UpdateTab } from "../../redux/slices/app";
import NightDayBtn from "../../components/nightDayModeBtn/nightDayBtn";
import truncateString from "utils/truncate";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "/app";

    case 1:
      return "/group";

    case 2:
      return "/call";

    case 3:
      return "/settings";

    default:
      break;
  }
};

const SideBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { tab } = useSelector((state) => state.app);
  const { userName } = useSelector((state) => state.userProfile);

  const navigate = useNavigate();

  const { onToggleMode } = useSettings();

  const selectedTab = tab;

  const handleChangeTab = (index) => {
    dispatch(UpdateTab({ tab: index }));
    navigate(getPath(index));
  };
  const themeColor = JSON.parse(localStorage.getItem("settings"));
  return (
    <Box
      sx={{
        height: "100vh",
        width: 100,
        backgroundColor: theme.palette.mode === "light" ? "#F0F4FA" : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack py={1} alignItems={"center"} justifyContent='space-between' sx={{ height: "100%" }}>
        <Stack alignItems={"center"} spacing={4}>
          <Box
            sx={{
              height: 64,
              width: 64,
              borderRadius: 1.5,
              // backgroundColor: theme.palette.primary.main,
            }}
            p={1}
          >
            <img src={themeColor?.themeMode === "light" ? Logo : LogoWhite} alt='Tawk' />
          </Box>
          <Stack sx={{ width: "max-content" }} direction='column' alignItems={"center"} spacing={3}>
            {Nav_Buttons.map((el, indx) => {
              return el.index === selectedTab ? (
                <Box
                  sx={{
                    backgroundColor: "#4338BC",
                    borderRadius: 1.5,
                  }}
                  p={1}
                  key={indx}
                >
                  <IconButton
                    onClick={() => {
                      handleChangeTab(el.index);
                      dispatch(selectProfileTab(""));
                    }}
                    sx={{ width: "max-content", color: "#ffffff" }}
                  >
                    {el.icon}
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  key={indx}
                  onClick={() => {
                    handleChangeTab(el.index);
                  }}
                  sx={{
                    width: "max-content",
                    color: theme.palette.mode === "light" ? "#080707" : theme.palette.text.primary,
                  }}
                >
                  {el.icon}
                </IconButton>
              );
            })}
            <Divider sx={{ width: 48 }} />
            {Nav_Setting.map((el, index) => {
              return el.index === selectedTab ? (
                <Box
                  sx={{
                    backgroundColor: "#4338BC",
                    borderRadius: 1.5,
                  }}
                  p={1}
                  key={index}
                >
                  <IconButton
                    onClick={() => {
                      handleChangeTab(el.index);
                    }}
                    sx={{ width: "max-content", color: "#ffffff" }}
                  >
                    {el.icon}
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  onClick={() => {
                    handleChangeTab(el.index);

                    // dispatch(UpdateTab(el.index));
                  }}
                  key={index}
                  sx={{
                    width: "max-content",
                    color: theme.palette.mode === "light" ? "#080707" : theme.palette.text.primary,
                  }}
                >
                  {el.icon}
                </IconButton>
              );
            })}
          </Stack>
        </Stack>
        <Stack spacing={1} mb={2} sx={{ textAlign: "center" }}>
          <NightDayBtn onChange={onToggleMode} defaultChecked={theme.palette.mode === "dark"} />
          <br />

          <ProfileMenu Useravatar={userName?._responseData?.image} />
          <Typography variant='subtitle2' sx={{ mt: 0 }}>
            {userName?._responseData?.userName && truncateString(userName?._responseData?.userName, 10)}
          </Typography>
          {/* <br /> */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SideBar;
