// routes
// theme
// components
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ThemeSettings from "./components/settings";
import ThemeProvider from "./theme";
import Router from "./routes";

import { closeSnackBar } from "./redux/slices/app";
import { socket } from "./socket";
import { Avatar, Button, Dialog, DialogActions, DialogContent, Slide } from "@mui/material";
import { Box, Stack, Typography, IconButton, Link, Divider } from "@mui/material";
import { SimpleBarStyle } from "./components/Scrollbar";

const vertical = "bottom";
const horizontal = "center";

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />);

function App() {
  const dispatch = useDispatch();

  const { severity, message, open } = useSelector((state) => state.app.snackbar);

  return (
    <>
      <ThemeProvider>
        <ThemeSettings>
          {" "}
          <Router />{" "}
        </ThemeSettings>
      </ThemeProvider>

      {message && open ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={4000}
          key={vertical + horizontal}
          onClose={() => {
            alert("This is clicked");
            dispatch(closeSnackBar());
          }}
        >
          <Alert
            onClose={() => {
              alert("This is clicked");
              dispatch(closeSnackBar());
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  );
}

export default App;
