import React from "react";
import { Avatar, Box, Fade, Menu, MenuItem, Stack } from "@mui/material";

import { faker } from "@faker-js/faker";

import { Profile_Menu } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser, resetSpecificState } from "../../redux/slices/auth";

import { AWS_S3_REGION, S3_BUCKET_NAME } from "../../config";
import { selectProfileTab } from "redux/slices/app";
import { Typography } from "@mui/material";

const ProfileMenu = ({ Useravatar = "" }) => {
  const { user } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user_name = user?.firstName;
  const user_img = `https://${S3_BUCKET_NAME}.s3.${AWS_S3_REGION}.amazonaws.com/${user?.avatar}`;

  const handleSidebarClick = (name) => {
    dispatch(selectProfileTab(name));
  };

  return (
    <Stack alignItems={"center"}>
      <Avatar
        id='profile-positioned-button'
        aria-controls={openMenu ? "profile-positioned-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={openMenu ? "true" : undefined}
        alt={user_name}
        src={`data:image/jpeg;base64,${Useravatar}`}
        onClick={handleClick}
        sx={{
          height: 50,
          width: 50,
        }}
      />

      {/* <img src={`data:image/jpeg;base64,${Useravatar}`} alt='' style={{ height: "100px", width: "100px" }} /> */}
      <Menu
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        TransitionComponent={Fade}
        id='profile-positioned-menu'
        aria-labelledby='profile-positioned-button'
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={1}>
          <Stack spacing={1}>
            {Profile_Menu.map((el, idx) => (
              <MenuItem onClick={handleClose} key={idx}>
                <Stack
                  onClick={() => {
                    if (el.title === "Sign Out") {
                      dispatch(resetSpecificState());
                      return dispatch(LogoutUser());
                    }
                    handleSidebarClick(el.title);

                    // if (idx === 0) {
                    //   navigate("/profile");
                    // } else if (idx === 1) {
                    //   navigate("/settings");
                    // } else {
                    // dispatch(LogoutUser());
                    // socket.emit("end", { user_id });
                    // }
                  }}
                  sx={{ width: 100 }}
                  direction='row'
                  alignItems={"center"}
                  justifyContent='space-between'
                >
                  <span>{el.title}</span>
                  {el.icon}
                </Stack>{" "}
              </MenuItem>
            ))}
          </Stack>
        </Box>
      </Menu>
    </Stack>
  );
};

export default ProfileMenu;
