import axios from "axios";
// config
import { BASE_URL } from "../config";
import { pushNotification } from "./reactToastnotification";
import { LogoutUser } from "redux/slices/auth";
import { dispatch } from "redux/store";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;

    if (data?._responseCode === 401) {
      // pushNotification("Session expired. ", "error");
      dispatch(LogoutUser());
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

export default axiosInstance;
