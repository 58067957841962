import { createSlice } from "@reduxjs/toolkit";

import { END_POINTS } from "../../utils/constants";
import axios from "../../utils/axios";
import { Chat_History, ChatList } from "data";
import { faker } from "@faker-js/faker";

import axiosInstance from "../../utils/axios";
// import { makeRequest } from "utils/axiosConfig";
const initialState = {
  allContacts: {
    allContactsLoader: false,
    allContactsResponse: {
      _responseCode: "",
      _responseData: [],
    },
  },
};

const allContactsSlice = createSlice({
  name: "new chat all contacts",
  initialState,
  reducers: {
    allContacts(state, action) {
      if (action.payload.responseData) {
        const { _responseCode, _responseData } = action.payload.responseData;

        const modifiedArray = _responseData?.map((user) => ({
          ...user,
          img: faker.image.avatar(),
          time: "04:00",
          unread: 0,
          pinned: false,
          online: true,
          name: user.userName,
          // msg: faker.music.songName(),
        }));

        state.allContacts.allContactsResponse._responseCode = _responseCode;
        state.allContacts.allContactsResponse._responseData = modifiedArray;
        state.allContacts.allContactsLoader = action.payload.getFriendRequests;
      }
    },

    resetStatusCode(state, action) {
      state.allContacts.allContactsResponse._responseCode = "";
      state.allContacts.allContactsResponse._responseData = [];
    },
  },
});

export default allContactsSlice.reducer;

export function getAllContactsService(phoneNumber) {
  return async (dispatch, getState) => {
    try {
      dispatch(allContactsSlice.actions.allContacts({ searchContactLoader: true, error: false }));
      const getUserId = localStorage.getItem("userID");

      const { data } = await axiosInstance.post(
        END_POINTS.GET_FRIENDS_LIST,
        { userID: getUserId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );
      dispatch(allContactsSlice.actions.allContacts({ searchContactLoader: false, responseData: data, error: false }));
    } catch (error) {
      console.log("error", error);
      dispatch(allContactsSlice.actions.allContacts({ searchContactLoader: false, error: true, responseData: error }));
    }
  };
}
