import React, { useEffect } from "react";
import { getAllContactsService } from "../../redux/slices/AllContacts";
import { useDispatch, useSelector } from "react-redux";
import { getFriendRequests, getRecentChatsList } from "../../redux/slices/newChat";
import { getUserProfileService } from "redux/slices/userProfile";
import { useLocation } from "react-router-dom";

const LoadDataComponent = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // console.log("========================= BEFORE ===================================");

        const promises = [
          dispatch(getFriendRequests()),
          dispatch(getRecentChatsList()),
          dispatch(getAllContactsService()),
          dispatch(getUserProfileService()),
        ];

        await Promise.all(promises);

        // console.log("========================= AFTER ===================================");
      } catch (error) {
        console.log("all data call error", error);
      } finally {
        setLoading(false);
        // console.log("========================= FINALLY ===================================");
      }
    };
    if (isLoggedIn) {
      fetchAllData();
    }
  }, [location]);

  return <>{children}</>;
};

export default LoadDataComponent;
