import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { FetchUserProfile, SelectConversation, showSnackbar } from "../../redux/slices/app";
import { socket, connectSocket } from "../../socket";
import { UpdateDirectConversation, AddDirectConversation, AddDirectMessage } from "../../redux/slices/conversation";

import { PushToVideoCallQueue, UpdateVideoCallDialog } from "../../redux/slices/videoCall";
import { getFriendRequests } from "../../redux/slices/newChat";
import { getAllContactsService } from "../../redux/slices/AllContacts";
import LoadDataComponent from "pages/dashboard/LoadDataComponent";
const DashboardLayout = () => {
  const isDesktop = useResponsive("up", "md");
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("userID"); //useSelector((state) => state.auth);
  // const { open_audio_notification_dialog, open_audio_dialog } = useSelector((state) => state.audioCall);
  // const { open_video_notification_dialog, open_video_dialog } = useSelector((state) => state.videoCall);
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const { conversations, current_conversation } = useSelector((state) => state.conversation.direct_chat);
  // useEffect(() => {
  //   fetchAllData();
  // }, []);

  // const fetchAllData = async () => {
  //   try {
  //     await Promise.all[(dispatch(getFriendRequests()), dispatch(getAllContactsService()))];
  //   } catch (error) {
  //     console.log("all data call error", error);
  //   }
  // };

  const handleCloseVideoDialog = () => {
    dispatch(UpdateVideoCallDialog({ state: false }));
  };

  // console.log('connecting to web sockets')
  //const socket = new WebSocket("ws://127.0.0.1:7072")

  var handShakeMessage = {
    packet: {
      code: "101",
      data: { connectionType: "text" },
      sender: user_id, // add userID for the loggedIn user
      protocol: "unicast",
    },
  };

  var msg = {
    packet: {
      code: "201",
      data: "hello, how are you?",
      sender: "bagaaieraon2xttybztpxuf4tdtsojngz5ryhj63e5qbt663l2mkvewnkie6a", // sender UserID for the loggedIn Session
      protocol: "unicast",
    },
    receivers: ["bagaaieralkgsr6mf3rg27qntwugdekpjymqzjhyifsmu3rhfjo65mou2weaa"],
  };

  // Connection opened
  // socket.addEventListener("open", event => {
  //   socket.send(JSON.stringify(handShakeMessage) + ";")
  //   console.log('message sent: ', handShakeMessage)
  // });

  // // Listen for messages
  // socket.addEventListener("message", event => {
  //   if(event.data){
  //     console.log("Message from server ", event.data)

  //   }
  // });

  useEffect(() => {
    if (isLoggedIn) {
      // window.onload = function () {
      //   if (!window.location.hash) {
      //     window.location = window.location + "#loaded";
      //     // window.location.reload();
      //   }
      // };
      // window.onload();
      if (!socket) {
        console.log("connecting to server using the user id :", user_id);
        connectSocket(user_id);
      }

      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(handShakeMessage) + ";");
        console.log("message sent: ", handShakeMessage);
      });
      // // // Listen for messages
      // socket.addEventListener("message", event => {
      //   if(event.data){
      //     console.log("Message from server ", event.data)
      //   }
      // });
      // socket.on("message", (data) => {
      //   // const message = data.message;
      //   // console.log(current_conversation, data);
      //   // // check if msg we got is from currently selected conversation
      //   // if (current_conversation?.id === data.conversation_id) {
      //   //   dispatch(
      //   //     AddDirectMessage({
      //   //       id: message._id,
      //   //       type: "msg",
      //   //       subtype: message.type,
      //   //       message: message.text,
      //   //       incoming: message.to === user_id,
      //   //       outgoing: message.from === user_id,
      //   //     })
      //   //   );
      //   // }
      // });
      //     socket.on("start_chat", (data) => {
      //       console.log(data);
      //       // add / update to conversation list
      //       const existing_conversation = conversations.find((el) => el?.id === data._id);
      //       if (existing_conversation) {
      //         // update direct conversation
      //         dispatch(UpdateDirectConversation({ conversation: data }));
      //       } else {
      //         // add direct conversation
      //         dispatch(AddDirectConversation({ conversation: data }));
      //       }
      //       dispatch(SelectConversation({ room_id: data._id }));
      //     });
      //     socket.on("new_friend_request", (data) => {
      //       dispatch(
      //         showSnackbar({
      //           severity: "success",
      //           message: "New friend request received",
      //         })
      //       );
      //     });
      //     socket.on("request_accepted", (data) => {
      //       dispatch(
      //         showSnackbar({
      //           severity: "success",
      //           message: "Friend Request Accepted",
      //         })
      //       );
      //     });
      //     socket.on("request_sent", (data) => {
      //       dispatch(showSnackbar({ severity: "success", message: data.message }));
      //     });
    }

    //   // Remove event listener on component unmount
    return () => {
      // socket?.off("new_friend_request");
      // socket?.off("request_accepted");
      // socket?.off("request_sent");
      // socket?.off("start_chat");
      //socket?.off("message");
      // socket?.off("audio_call_notification");
    };
  }, [isLoggedIn, socket]);

  if (!isLoggedIn) {
    return <Navigate to={"/auth/login"} />;
  }

  return (
    <>
      <Stack direction='row'>
        {isDesktop && (
          // SideBar
          <SideNav />
        )}
        {/* <LoadDataComponent /> */}
        <Outlet />
      </Stack>
    </>
  );
};

export default DashboardLayout;
