import { createSlice } from "@reduxjs/toolkit";

import { END_POINTS } from "../../utils/constants";
import { Chat_History, ChatList } from "../../data";

import { toast } from "react-hot-toast";

import axiosInstance from "../../utils/axios";
import { getMessageTimestamp } from "utils/getMessageTimestamp";

// Initial state for the new slice

const initialState = {
  Chat_History: [],
  ChatList: [],
  selected_chat: [],
  searchNewContact: {
    searchContactLoader: false,
    searchContactResponseData: {
      _responseCode: "",
      _responseData: "",
    },
  },
  addNewContact: {
    addContactLoader: false,
    addContactResponseData: {
      _responseCode: "",
      _responseData: "",
    },
  },

  approveFriendRequest: {
    approveRequestLoader: false,
    approveRequestResponse: {
      _responseCode: "",
      _responseData: "",
    },
  },
  getFriendRequests: {
    getFriendLoader: false,
    getFriendResponse: {
      _responseCode: "",
      _responseData: [],
    },
  },
  chatterUserName: "",
  loaderid: "",
  activeChatId: "",
};

const yourSlice = createSlice({
  name: "new chat",
  initialState,
  reducers: {
    addNewMsgToChat: (state, action) => {
      const { chatId, type, incoming, outgoing, message, messageTime } = action.payload;
      const chatIndex = state.Chat_History.findIndex((chat) => chat.chatId === chatId);

      if (chatIndex !== -1) {
        // Chat exists, update its chatHistory array
        state.Chat_History[chatIndex].chatHistory.push({
          ...action.payload,
        });
      } else {
        state.Chat_History.push({
          chatId,
          chatHistory: [
            {
              type,
              incoming,
              outgoing,
              message,
              messageTime,
            },
          ],
        });
      }
    },

    searchContact(state, action) {
      state.searchNewContact.searchContactResponseData = action.payload.responseData;
      state.searchNewContact.searchContactLoader = action.payload.searchContactLoader;
    },
    addSearchContact(state, action) {
      state.addNewContact.addContactResponseData = action.payload.responseData;
      state.addNewContact.addContactLoader = action.payload.addContactLoader;
    },
    approveFriendRequest(state, action) {
      const { _responseCode, _responseData } = action.payload.responseData;

      state.approveFriendRequest.approveRequestResponse._responseCode = _responseData;
      state.approveFriendRequest.approveRequestResponse._responseCode = _responseCode;
      state.approveFriendRequest.approveRequestLoader = action.payload.approveRequestLoader;
    },

    getFriendRequests(state, action) {
      const { _responseCode, _responseData } = action.payload.responseData;

      const resMap = _responseData?.map((items) => {
        return {
          id: items._id,
          name: items.userName,
          phoneNumber: items.phoneNumber,
          loadingState: false,
        };
      });

      state.getFriendRequests.getFriendResponse._responseCode = _responseCode;
      state.getFriendRequests.getFriendResponse._responseData = resMap || [];
      state.getFriendRequests.getFriendLoader = action.payload.getFriendRequests;
    },

    getLoaderId(state, action) {
      state.loaderid = action.payload.requestID;
    },

    // ===================================

    addContactObjectToChatHis(state, action) {
      const newUsersChat = action.payload;
      let updatedChat = [...state.Chat_History];

      newUsersChat.forEach((user) => {
        const existingChatIndex = state.Chat_History.findIndex((chat) => chat.chatId === user._id);
        if (existingChatIndex === -1) {
          updatedChat.push({
            chatId: user._id,
            chatHistory: [],
          });
        }
      });

      state.Chat_History = updatedChat;
    },

    checkIfNewChatavailable(state, action) {
      let newUsers = action.payload;

      let existingUsersChatHistory = state.Chat_History;

      let updatedChatList = [...state.ChatList];

      newUsers.forEach((user) => {
        const chatHistory = existingUsersChatHistory.find((chat) => chat.chatId === user._id);

        if (chatHistory && chatHistory.chatHistory.length > 0) {
          const existingChatIndex = updatedChatList.findIndex((chat) => chat._id === user._id);

          if (existingChatIndex === -1) {
            updatedChatList.push({ ...user, createdAt: getMessageTimestamp(), updatedAt: new Date() });
          } else {
            // If the user already exists in ChatList, you can handle it here
            // For example, you may want to update some properties of the existing user
            // updatedChatList[existingChatIndex].someUpdateFunction();
          }
        }
      });
      // remove duplicates
      const uniqueArray = updatedChatList.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          updatedChatList.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });

      state.ChatList = uniqueArray;
    },

    updateChatListTime(state, action) {
      let token = localStorage.getItem("userToken");
      const { chatterId, chatterUpdateTime } = action.payload;
      const copyUpdateList = [...state.ChatList];
      copyUpdateList.forEach((elemens) => {
        if (elemens?._id === chatterId) {
          elemens.updatedAt = chatterUpdateTime;
        }
      });
      state.ChatList = copyUpdateList;
    },

    updateUserLastMsgInRecentChat(state, action) {
      state.ChatList.forEach((elements) => {
        if (elements._id === action.payload.id) {
          elements.msg = action.payload.lastMsg;
        }
      });
    },

    getActiveChatId(state, action) {
      state.activeChatId = action.payload;
    },

    getChatterUserName(state, action) {
      state.chatterUserName = action.payload;
    },

    // =================== chatList syngc ===================================

    getRecentCahts(state, action) {
      if (action.payload.responseData) {
        const {
          responseData: { _responseData },
        } = action.payload;
        if (_responseData?.chats?.length > 0) {
          // state.ChatList = _responseData?.chats;
        }
      }
    },

    readUnreadMsgs(state, action) {
      const { Chat_History, activeChatId } = action.payload;
      let currentChathistory = [...state.Chat_History];
      let currentCctiveChatId = activeChatId;

      // console.log("ACTIVE CHATTTTT", currentCctiveChatId);

      currentChathistory.forEach((elements) => {
        // console.log("paretn loo ================p", elements);
        if (elements.chatId === currentCctiveChatId) {
          // console.log("paretn loo ====== in trueeee==========p", elements);

          elements?.chatHistory?.forEach((insideelements) => {
            // console.log("paretn loo ====== childdddddd==========p insideelements", insideelements);
            if (insideelements.unread) {
              insideelements.unread = false;
            }
          });
        }
      });
    },

    resetStatusCode(state, action) {
      state.searchNewContact.searchContactResponseData._responseCode = "";
      state.searchNewContact.searchContactResponseData._responseData = "";
      state.addNewContact.addContactResponseData._responseCode = "";
      state.addNewContact.addContactResponseData._responseData = "";
      state.approveFriendRequest.approveRequestResponse._responseCode = "";
      state.approveFriendRequest.approveRequestResponse._responseData = "";
    },
  },
});

// Export the reducer and actions
export const {
  addNewMsgToChat,
  addContactObjectToChatHis,
  getActiveChatId,
  resetStatusCode,
  getLoaderId,
  checkIfNewChatavailable,
  getChatterUserName,
  updateUserLastMsgInRecentChat,
  readUnreadMsgs,
} = yourSlice.actions;
export default yourSlice.reducer;

export function searchNewContact(phoneNumber) {
  return async (dispatch, getState) => {
    try {
      dispatch(yourSlice.actions.searchContact({ searchContactLoader: true, error: false, responseData: {} }));

      const { data } = await axiosInstance.post(
        END_POINTS.SEARCH_CONTACT,
        { phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );

      dispatch(yourSlice.actions.searchContact({ searchContactLoader: false, responseData: data, error: false }));
    } catch (error) {
      console.log("error from slie", error);
      dispatch(yourSlice.actions.searchContact({ searchContactLoader: false, error: true, responseData: error }));
    }
  };
}

export function addSearchedContactService(userIDTo) {
  return async (dispatch, getState) => {
    try {
      dispatch(yourSlice.actions.addSearchContact({ addContactLoader: true, error: false, responseData: {} }));

      const loadingToast = toast.loading("Sending...");

      const { data } = await axiosInstance.post(END_POINTS.SEND_FRIEND_REQUEST, userIDTo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      toast.dismiss(loadingToast);
      dispatch(yourSlice.actions.addSearchContact({ addContactLoader: false, responseData: data, error: false }));
    } catch (error) {
      dispatch(yourSlice.actions.addSearchContact({ addContactLoader: false, error: true, responseData: error }));
    }
  };
}

export function approveFriendRequest(values) {
  return async (dispatch, getState) => {
    try {
      dispatch(yourSlice.actions.approveFriendRequest({ approveRequestLoader: true, error: false, responseData: {} }));
      dispatch(yourSlice.actions.getLoaderId(values));

      const { data } = await axiosInstance.post(END_POINTS.APPROVE_CONTACT_REQUREST, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });

      dispatch(yourSlice.actions.getLoaderId("done"));
      dispatch(getFriendRequests());
      dispatch(yourSlice.actions.approveFriendRequest({ approveRequestLoader: false, responseData: data, error: false }));
    } catch (error) {
      dispatch(yourSlice.actions.approveFriendRequest({ approveRequestLoader: false, error: true, responseData: error }));
    }
  };
}

export function getFriendRequests(values) {
  return async (dispatch, getState) => {
    try {
      const { data } = await axiosInstance.post(END_POINTS.GET_PENDING_REQUESTS, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      dispatch(yourSlice.actions.getFriendRequests({ getFriendRequests: false, responseData: data, error: false }));
    } catch (error) {
      dispatch(yourSlice.actions.getFriendRequests({ getFriendRequests: false, error: true, responseData: error }));
    }
  };
}

export function checkIfNewChatavailableNew(values) {
  return async (dispatch, getState) => {
    try {
      const oldSt = getState().allContacts.allContacts.allContactsResponse._responseData;

      //checkIfNewChatavailable
      dispatch(yourSlice.actions.checkIfNewChatavailable(oldSt));
    } catch (error) {
      console.log(error);
      // dispatch(yourSlice.actions.getFriendRequests({ getFriendRequests: false, error: true, responseData: error }));
    }
  };
}

export function updateChatListTimeDispatcher(chatId) {
  return async (dispatch, getState) => {
    const chatHistoryState = getState().newChatSystem.Chat_History;

    const filterChatHis =
      chatHistoryState?.filter((items) => {
        return items.chatId === chatId;
      }) || [];

    let chathistObj = filterChatHis[0]?.chatHistory || [];

    const findLatestMessageTime = () => {
      if (!chathistObj || chathistObj.length === 0) {
        return new Date();
      }

      let latestMessageTime = new Date(chathistObj[0].messageTime); // Initialize with the first message time
      for (let i = 1; i < chathistObj.length; i++) {
        const currentMessageTime = new Date(chathistObj[i].messageTime);

        // Compare current message time with the latest message time
        if (currentMessageTime > latestMessageTime) {
          latestMessageTime = currentMessageTime;
        }
      }

      return latestMessageTime.toUTCString(); // Return the most recent messageTime in UTC format
    };

    const mostRecentMessageTime = findLatestMessageTime();

    dispatch(yourSlice.actions.updateChatListTime({ chatterId: chatId, chatterUpdateTime: mostRecentMessageTime }));
  };
}

export async function syncChatListWithServer(chatlist, token) {
  try {
    console.log("recieving chat", chatlist);
    console.log("recieving token", token);

    const { data } = await axiosInstance.post(
      END_POINTS.SYNC_CHAT_LIST,
      { chatList: chatlist },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("recieving data", data);
  } catch (error) {
    console.log("error in cha sync", error);
  }
}

export function getRecentChatsList(values) {
  return async (dispatch, getState) => {
    try {
      const { data } = await axiosInstance.post(
        END_POINTS.GET_RECENT_CHAT_LIST,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );
      dispatch(yourSlice.actions.getRecentCahts({ synChatLoader: false, responseData: data, error: false }));
    } catch (error) {
      dispatch(yourSlice.actions.getRecentCahts({ synChatLoader: false, error: true, responseData: error }));
    }
  };
}
