// import io from "socket.io-client"; // Add this

export let socket; 
//new WebSocket("ws://127.0.0.1:7072")

// let socket;

let callback = null;

var handShakeMessage = {
  packet: {
    code: '101',
    data: { connectionType: 'text' },
    //sender: user_id, // add userID for the loggedIn user
    protocol: 'unicast'
  }
}

export const connectSocket = (user_id) => {
  var localURL = 'ws://127.0.0.1:7072'
  var productionURL = 'wss://node.dnotifier.com'

  socket = new WebSocket(productionURL) // io("ws://127.0.0.1:7072");

  socket.addEventListener("open", event => {
    handShakeMessage.packet.sender = user_id;
    socket.send(JSON.stringify(handShakeMessage) + ";")
    console.log('message sent: ', handShakeMessage)
  });

  
} 

export const sendMessage = (msg)=> {
  if(msg){
    socket.send(JSON.stringify(msg) + ";")
  }
}

export const receiveMessage = (callback)=> {
  callback = callback;
}

// Add this -- our server will run on port 4000, so we connect to it from here

// export {socket, connectSocket};
