// **** APP END POINTS **** //

export const END_POINTS = {
  // Auth end points

  USER_LOGIN: "/api/v1/public/signin",
  USER_VERIFY_LOGIN_OTP: "/api/v1/public/validate-signin",
  USER_REGISTER: "/api/v1/public/signup",
  USER_VERIFY_REGISTER_OTP: "/api/v1/public/validate-signup",
  RESEND_OTP: "/api/v1/public/resend-otp",

  // friend list end points

  SEARCH_CONTACT: "/api/v1/contact/search",
  SEND_FRIEND_REQUEST: "/api/v1/contact/send-request",
  APPROVE_CONTACT_REQUREST: "/api/v1/contact/approve-request",
  GET_PENDING_REQUESTS: "/api/v1/contact/get-requests",
  GET_FRIENDS_LIST: "/api/v1/contact/get-friends",

  // user profile end points
  GET_USER_PROFILE: "/api/v1/profile/getProfile",
  UPDATE_USER_PROFILE: "/api/v1/profile//updateProfile",
  UPDATE_USER_AVATAR: "/api/v1/profile/updateAvatar",
  // chatlist end points

  SYNC_CHAT_LIST: "/api/v1/chat/syncChatList",
  GET_RECENT_CHAT_LIST: "/api/v1/chat/getRecentChats",
};
