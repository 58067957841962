import { createSlice } from "@reduxjs/toolkit";

import { END_POINTS } from "../../utils/constants";

import axiosInstance from "../../utils/axios";

const initialState = {
  userName: "",
  userProfileUpdate: {
    loader: false,
    responseData: {
      _responseCode: "",
      _responseData: "",
    },
  },
  userAvatarUpdate: {
    userAvatarLoader: false,
    userAvatarResponse: {
      _responseCode: "",
    },
  },
};

const allContactsSlice = createSlice({
  name: "user profile",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.userName = action.payload.responseData;
    },

    updateUserStatusUserName(state, action) {
      state.userProfileUpdate.responseData._responseCode = action.payload.responseData?._responseCode;
      state.userProfileUpdate.loader = action.payload.loader;
    },
    updateUserAvatar(state, action) {
      if (action.payload.responseData) {
        const { _responseCode, _responseData } = action.payload.responseData;

        state.userAvatarUpdate.userAvatarLoader = action.payload.loader;
        state.userAvatarUpdate.userAvatarResponse._responseCode = _responseCode;
      }
    },
    resetStatusCode(state) {
      state.userProfileUpdate.responseData._responseCode = "";
      state.userAvatarUpdate.userAvatarResponse._responseCode = "";
    },
  },
});

export const { resetStatusCode } = allContactsSlice.actions;

export default allContactsSlice.reducer;

export function getUserProfileService(phoneNumber) {
  return async (dispatch, getState) => {
    try {
      const { data } = await axiosInstance.post(
        END_POINTS.GET_USER_PROFILE,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );

      dispatch(allContactsSlice.actions.getUserProfile({ responseData: data }));
    } catch (error) {
      dispatch(allContactsSlice.actions.getUserProfile({ responseData: error }));
    }
  };
}

export function userProfileUpdateService(values) {
  return async (dispatch, getState) => {
    dispatch(allContactsSlice.actions.updateUserStatusUserName({ loader: true, responseData: {} }));
    try {
      const { data } = await axiosInstance.post(END_POINTS.UPDATE_USER_PROFILE, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      dispatch(allContactsSlice.actions.updateUserStatusUserName({ loader: false, responseData: data }));
    } catch (error) {
      dispatch(allContactsSlice.actions.updateUserStatusUserName({ responseData: error }));
    }
  };
}

export function userAvatarUpdateService(values) {
  return async (dispatch, getState) => {
    dispatch(allContactsSlice.actions.updateUserAvatar({ loader: true }));
    try {
      const { data } = await axiosInstance.post(
        END_POINTS.UPDATE_USER_AVATAR,
        { image: values },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );
      dispatch(allContactsSlice.actions.updateUserAvatar({ loader: false, responseData: data }));
    } catch (error) {
      dispatch(allContactsSlice.actions.updateUserAvatar({ responseData: error }));
    }
  };
}
